<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import type { ContentResponse } from 'types';

// Register views
const FrontPage = resolveComponent('LazyFrontPage');
const PageDefault = resolveComponent('LazyPageDefault');
const NewsListPage = resolveComponent('LazyNewsListPage');
const NewsPage = resolveComponent('LazyNewsPage');
const SearchPage = resolveComponent('LazySearchPage');
const ProductListPage = resolveComponent('LazyProductListPage');
const ProductDetailPage = resolveComponent('LazyProductDetailPage');
const AzureB2CPage = resolveComponent('LazyAzureB2CPage');

// Composables
const root = useRoot();
const config = useRuntimeConfig();
const { locale } = useI18n();
const navigation = useNavigation();
const appInsights = useApplicationInsights();

// Fetch data from API
const { query, path } = useRoute();
const { getContent, getPreview } = useContentApi();
const encodedPath = encodeURIComponent(path);
const pageData = shallowRef<ContentResponse>();
const isPreview = computed(() => query?.preview === 'true');

async function getPageData() {
  const { data, error } = await useAsyncData(
    encodedPath,
    () => (isPreview.value ? getPreview(useFrontendBase(path)) : getContent(useFrontendBase(path))),
    { deep: false }
  );

  if (!data.value || error.value) {
    throw createError({
      statusCode: 404,
      fatal: true
    });
  }
  pageData.value = data.value;
}

await getPageData();

// Handle Redirect
if (
  pageData.value?.metadata.statusCode &&
  ((pageData.value?.metadata.statusCode > 300 && pageData.value?.metadata.statusCode < 400) ||
    pageData.value?.metadata.statusCode === 401 ||
    pageData.value?.metadata.statusCode === 403)
) {
  const relativePath = handleRelativeUrl(pageData.value?.metadata.redirectUrl) || '/';
  navigateTo(relativePath, {
    redirectCode: pageData.value?.metadata.statusCode,
    external: true
  });
}

const viewTemplate = computed(() => {
  let view = null;

  if (!pageData.value) {
    return view;
  }

  const { alias } = pageData.value;

  switch (alias) {
    case 'home':
      view = FrontPage;
      break;
    case 'contentPage':
      view = PageDefault;
      break;
    case 'productDetailPage':
      view = ProductDetailPage;
      break;
    case 'productListPage':
      view = ProductListPage;
      break;
    case 'newsListPage':
      view = NewsListPage;
      break;
    case 'newsPage':
      view = NewsPage;
      break;
    case 'searchResults':
      view = SearchPage;
      break;
    case 'azureB2CPage':
      view = AzureB2CPage;
      break;
    default:
      break;
  }

  return view;
});

// Set MetaData
if (pageData.value?.metadata) {
  navigation.updateActiveContentMetaData(pageData.value.metadata);
}

// Compute page title
const pageTitle = computed<string>(() => {
  const seoTitle = pageData.value?.content.seoTitle;
  const metaTitle = pageData.value?.metadata.name;

  return seoTitle || metaTitle;
});

// Compute page description
const pageDescription = computed(() => {
  return pageData.value?.content.seoDescription;
});

// Compute robots meta
const pageIndex = computed(() => {
  const index = pageData.value?.content.robotsIndex;
  const follow = pageData.value?.content.robotsFollow;

  if (config.public.environment !== 'production' || isPreview.value) {
    return 'noindex, nofollow';
  }

  let robots = '';
  robots = index ? 'index' : 'noindex';
  robots += follow ? ', follow' : ', nofollow';

  return robots;
});

// Compute HTML Lang attribute
const htmlLang = computed(() => {
  const culture = root.settings.metadata.culture;

  return culture;
});

// Compute HREF Lang
const hrefLang = computed(() => {
  return locale.value;
});

// Compute canonical
const canonical = computed(() => {
  let canonicalUrl = '';

  if (pageData.value?.content.canonicalURL !== '') {
    canonicalUrl = pageData.value?.content.canonicalURL;
  } else {
    canonicalUrl = pageData.value?.metadata.url ?? '';
  }

  return canonicalUrl;
});

// Define SEO data
useHead({
  title: pageTitle,
  htmlAttrs: {
    lang: htmlLang.value
  },
  titleTemplate(title: string | undefined) {
    return title ? `${title} | ${root.settings?.content.titleSiteName}` : root.settings?.content.titleSiteName;
  },
  meta: [
    { name: 'description', content: pageDescription.value },
    { name: 'robots', content: pageIndex.value },

    { name: 'og:title', content: pageTitle.value },
    { name: 'og:description', content: pageDescription.value },
    { name: 'og:type', content: 'website' },
    { name: 'og:url', content: pageData.value?.metadata.url },

    { name: 'twitter:title', content: pageTitle.value },
    { name: 'twitter:description', content: pageDescription.value },
    { name: 'twitter:card', content: 'summary_large_image' }
  ],
  link: [
    { rel: 'canonical', href: canonical.value },
    {
      rel: 'alternate',
      hreflang: hrefLang.value,
      href: pageData.value?.metadata.url
    },
    {
      rel: 'icon',
      href: useCdnBase(`${root.settings?.content?.favicon?.url}?width=16&height=16&format=png&quality=80`),
      sizes: '16x16'
    },
    {
      rel: 'icon',
      href: useCdnBase(`${root.settings?.content?.favicon?.url}?width=32&height=32&format=png&quality=80`),
      sizes: '32x32'
    },
    {
      rel: 'apple-touch-icon',
      href: useCdnBase(`${root.settings?.content?.favicon?.url}?width=180&height=180&format=png&quality=80`),
      sizes: '180x180'
    }
  ]
});

// Define schema.org data for individual pages
defineWebPage({
  name: pageTitle.value,
  description: pageDescription.value,
  url: pageData.value?.metadata.url
});

if (appInsights) {
  appInsights.trackPageView({
    name: pageTitle.value,
    uri: pageData.value?.metadata.url,
    pageType: pageData.value?.alias
  });
}
</script>

<template>
  <div>
    <span
      v-if="isPreview"
      class="fixed bottom-0 z-[500] w-full bg-primary py-2 text-center text-white"
    >
      {{ $t(`General.Preview`) }}
    </span>

    <Component
      :is="viewTemplate"
      v-if="viewTemplate"
      :blocks="pageData?.content.blocks"
      :content="pageData?.content"
      :breadcrumbs="pageData?.navigation.breadcrumb"
      :metadata="pageData?.metadata"
    />
  </div>
</template>
