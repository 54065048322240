import type { ContentResponse } from 'types/index';

export default function () {
  const getContent = (url: string) => {
    return UseBaseApi<ContentResponse>('/api/content', {
      params: {
        url
      },
      keepalive: true
    });
  };

  const getPreview = (url: string) => {
    return UseBaseApi<ContentResponse>('/api/preview', {
      params: {
        url
      },
      keepalive: true
    });
  };

  async function getListContent<T>(
    url: string,
    page: number,
    pageSize: number,
    tags: string = '',
    recursive: boolean = false
  ) {
    return await UseBaseApi<T>('/api/content/list', {
      params: {
        url,
        page,
        pageSize,
        recursive,
        tags
      },
      keepalive: true
    });
  }

  return {
    getContent,
    getPreview,
    getListContent
  };
}
